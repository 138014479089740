@import './global.css';

.header-container {
    height: auto;
    background-image: url("../images/billc-mural-cropped.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--bi-salmon);
    color: var(--bi-beige);
}

/*.nav-bar-main {*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

.logo-style {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    padding: 5px;
    margin: 5px;

}


