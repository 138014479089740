
.loading-spinner {
    height: 120px;
    width: 120px;

    animation: spin 1.5s linear infinite;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes innout {
    0% {
        height: 60px;
        transform: rotate(0deg);
    }
    50% {
        height: 120px;
        transform: rotate(360deg);
    }
    100% {
        height: 60px;
        transform: rotate(720deg);
    }
}

@keyframes woah {
    0% {
        height: 10px;
        transform: rotate(0deg);
    }

    100% {
        height: 1000px;
        transform: rotate(1440deg);
    }
}