@import "./global.css";

.required-text, .validation-error-text{
    font-size: small;
    opacity: 0.7;
    color: var(--bi-beige);
}

.validation-text{
    font-size: small;
    color: red;
}

.artist-select {
    border: 2px solid var(--bi-beige);
    border-radius: 0.5rem;
}

.artist-select:hover {
    opacity: 0.6;
    border-color: var(--bi-gold);
}

.artist-select:active {
    opacity:0.4;
    background-color: var(--bi-maroon);
}

/*hide the radio button*/
.artist-select-radio {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.artist-image {
    border: 2px solid var(--bi-beige);
}

.artist-image.selected {
    border-color: var(--bi-gold);
}


.artist-select.selected {
    border: 2px solid var(--bi-gold);
    background-color: var(--bi-maroon);
    border-radius: 0.5rem;
}

/* custom calendar classes */

.time-button.active {
    background-color: var(--bi-gold) !important;
    color: black !important;
}

.date-has-times {
    background-color: var(--bi-maroon);
    color: var(--bi-beige);
}

/*  react-calendar */
.react-calendar {
    width: 350px;
    max-width: 100%;
    background: #FFFFFF;
    border: 1px solid var(--bi-maroon);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 88px;
    background: none;
}
.react-calendar__navigation__label:disabled,
.react-calendar__navigatio__label {
    background-color: #FFFFFF;
    color: #000000;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__navigation__arrow:disabled {
    background-color: var(--bi-disabled);
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
    color: #000000;
    padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6px;

    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
    /*border-radius: 0.5rem;*/
}

.react-calendar__tile:disabled {
    background-color: var(--bi-disabled);
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: var(--bi-gold);
    color: black;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: var(--bi-emerald);
    color: var(--bi-beige);
    opacity: 0.6;
}

.react-calendar__tile--hasActive {
    background: var(--bi-gold);
    color: black;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: var(--bi-gold);
    color: black;
}

.react-calendar__tile--active {
    background: var(--bi-gold);
    color: black;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--bi-gold);
    color: black;
}

/* PAYMENT */
.clover-footer {

}

.field {
    height: 40px;
    margin-bottom: 0.5rem;
}

.field, input{
    border: 1px solid black;
    border-radius: .375rem;
}

.payment-input-errors {
    color: red;

}